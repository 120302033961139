import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './components/Login/index';
import Dashboard from './components/Dashboard';
import AssetForm from './components/AssetForm';
import InventoryPage from './components/AssetView/AssetDetails';
import MainLayout from './components/MainLayout';

// Bloquear logs no ambiente de produção
if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
  console.warn = () => { };
  console.error = () => { };
}

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Rotas sem layout */}
        <Route path="/" element={<Navigate to="/auth/login" />} />
        <Route path="/auth/login" element={<LoginPage />} />

        {/* Rotas com MainLayout */}
        <Route
          path="/home/dashboard"
          element={
            <MainLayout>
              <Dashboard />
            </MainLayout>
          }
        />
        <Route
          path="/cadastro/ativos"
          element={
            <MainLayout>
              <AssetForm />
            </MainLayout>
          }
        />
        <Route
          path="/gerenciamento/ativos"
          element={
            <MainLayout>
              <InventoryPage />
            </MainLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
