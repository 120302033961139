import React, { useEffect, useState } from 'react';
import { BellIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

function Header() {
    const [notifications, setNotifications] = useState([]);
    const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [userProfile, setUserProfile] = useState(null); // Estado para armazenar o perfil do usuário

    const fetchNotifications = async () => {
        try {
            const response = await fetch('', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch notifications');
            }
            const data = await response.json();
            setNotifications(data);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    const fetchUserProfile = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Token not found');
            }

            const response = await fetch('', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user profile');
            }

            const data = await response.json();
            setUserProfile(data);
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
        fetchUserProfile();
        const intervalId = setInterval(fetchNotifications, 30000);
        return () => clearInterval(intervalId);
    }, []);

    const handleNotificationClick = () => {
        setShowNotificationDropdown(prev => !prev);
        setShowUserDropdown(false);
    };

    const handleUserClick = () => {
        setShowUserDropdown(prev => !prev);
        setShowNotificationDropdown(false);
    };

    return (
        <header className="flex items-center justify-between px-4 py-2">
            <div className="flex items-center flex-1 max-w-xs lg:max-w-md">
                {/* Barra de pesquisa */}
            </div>

            <div className="flex items-center space-x-4 relative">
                {/* Ícone de Notificação */}
                <button
                    className="relative p-2 bg-white rounded-full shadow-sm hover:bg-gray-100"
                    onClick={handleNotificationClick}
                >
                    <BellIcon className="w-5 h-5 text-gray-600" />
                    {notifications.length > 0 && (
                        <span className="absolute top-1 right-1 inline-block w-2.5 h-2.5 bg-red-600 rounded-full border-2 border-white"></span>
                    )}
                </button>

                {/* Dropdown de Notificações */}
                {showNotificationDropdown && (
                    <div className="absolute right-0 top-full mt-1 w-48 bg-white shadow-lg rounded-md z-50">
                        <ul className="max-h-60 overflow-y-auto">
                            {notifications.length === 0 ? (
                                <li className="px-4 py-2 text-gray-500">Sem notificações</li>
                            ) : (
                                notifications.map((notification, index) => (
                                    <li key={index} className="px-4 py-2 hover:bg-gray-100">
                                        {notification.message}
                                    </li>
                                ))
                            )}
                        </ul>
                    </div>
                )}

                {/* Avatar */}
                <div className="relative">
                    <button
                        className="w-8 h-8 rounded-full bg-gray-50 flex items-center justify-center"
                        onClick={handleUserClick}
                    >
                        <SupervisedUserCircleIcon style={{ fontSize: '1.5rem', color: '#4A5568' }} />
                    </button>

                    {/* Dropdown do Usuário */}
                    {showUserDropdown && (
                        <div className="absolute right-0 mt-2 w-58 bg-white shadow-lg rounded-lg p-4 z-50">
                            <div className="flex items-center space-x-3 mb-4">

                                <div className="flex items-center space-x-3 mb-4">
                                    {/* Verificando se há imagem no perfil, se não, exibe a inicial */}
                                    <div className="w-10 h-10 rounded-full bg-blue-600 flex items-center justify-center text-white font-semibold">
                                        {userProfile && userProfile.profilePicture ? (
                                            <img
                                                src={userProfile.profilePicture} // Caminho da imagem de perfil
                                                className="w-full h-full object-cover rounded-full"
                                            />
                                        ) : (
                                            userProfile && userProfile.username ? userProfile.username.charAt(0).toUpperCase() : 'D'
                                        )}
                                    </div>

                                    <div>
                                        <p className="text-sm font-medium">
                                            {userProfile && userProfile.username ? userProfile.username : 'Demo'}
                                        </p>
                                        <p className="text-xs text-gray-500">{userProfile && userProfile.email ? userProfile.email : 'demo@pinnsystem.com'}</p>
                                    </div>
                                </div>

                            </div>

                            <button className="w-full bg-blue-500 text-white py-1.5 rounded-md text-sm font-medium mb-4">
                                Indicar-nos
                            </button>
                            <ul className="text-sm text-gray-600">
                                <li className="py-2 hover:bg-gray-100 cursor-pointer">
                                    <Link to="/general/account">Perfil</Link> {/* Link para a rota /perfil */}
                                </li>
                                <li className="py-2 hover:bg-gray-100 cursor-pointer">
                                    <Link to="/general/inscricao">Inscrição</Link> {/* Link para a rota /inscricao */}
                                </li>
                                <li className="py-2 hover:bg-gray-100 cursor-pointer">
                                    <Link to="/suporte">Suporte</Link> {/* Link para a rota /suporte */}
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
