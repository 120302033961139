import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericAssetIcon from './GenericAssetIcon'; // Assuming this exists

// Mock Images (you'll need to replace these with actual paths or base64 encoded images)
const mockImages = [
  '/mock-asset-1.webp',
  '/mock-asset-2.webp',
  '/mock-asset-3.webp',
  '/mock-asset-4.jpeg',
  '/mock-asset-5.jpeg',
];

// Function to generate fake assets
function generateFakeAssets(count = 12) {
  const categories = ['TI', 'Infraestrutura', 'Escritório', 'Mobiliário', 'Veículos'];
  const statuses = ['Disponível', 'Em uso', 'Em manutenção'];
  const responsibles = ['João Silva', 'Maria Souza', 'Pedro Santos', 'Ana Oliveira', 'Carlos Pereira'];

  return Array.from({ length: count }, (_, index) => ({
    id: `asset-${index + 1}`,
    name: `Ativo ${index + 1}`,
    category: categories[Math.floor(Math.random() * categories.length)],
    status: statuses[Math.floor(Math.random() * statuses.length)],
    responsible: responsibles[Math.floor(Math.random() * responsibles.length)],
    image_url: mockImages[Math.floor(Math.random() * mockImages.length)],
    description: `Descrição detalhada do ativo ${index + 1}. Este é um ativo de exemplo para demonstração do sistema PinnSystem.`,
    acquisition_date: new Date(
      2020 + Math.floor(Math.random() * 4), 
      Math.floor(Math.random() * 12), 
      Math.floor(Math.random() * 28)
    ).toISOString().split('T')[0],
    value: (Math.random() * 10000).toFixed(2)
  }));
}

function InventoryPage() {
  const [assets, setAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const openModal = (asset) => {
    setSelectedAsset(asset);
    setIsModalOpen(true);
    navigate(`/asset/${asset.id}`);
  };

  const closeModal = () => {
    setSelectedAsset(null);
    setIsModalOpen(false);
  };

  const handleRedirect = () => {
    navigate('/cadastro/ativos');
  };

  useEffect(() => {
    document.title = `Ativos - PinnSystem`;

    // Simulate API call delay
    const timer = setTimeout(() => {
      const fakeAssets = generateFakeAssets();
      setAssets(fakeAssets);
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // Rest of the component remains the same as the original, with minor modifications
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-white p-8">
      {/* Header */}
      <div className="flex items-center space-x-2 text-gray-500 text-sm">
        <span className="text-blue-600 font-medium">Ativos</span>
        <span>/</span>
        <span className="font-semibold text-gray-900">Inventário (Demonstração)</span>
      </div>

      {/* Title */}
      <div className="flex justify-between items-center mt-4">
        <h1 className="text-3xl font-bold text-gray-800">Inventário de Ativos (Demonstração)</h1>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 transition"
          onClick={handleRedirect}
        >
          + Novo Ativo
        </button>
      </div>

      {/* Filter and Action Section */}
      <div className="flex justify-between items-center mt-6">
        {/* Filter Bar */}
        <div className="relative w-full max-w-md">
          <input
            type="text"
            placeholder="Procurar por ativos"
            className="px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-600 transition"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="absolute right-0 top-0 h-full px-4 bg-blue-500 text-white rounded-r-md">
            Filtrar
          </button>
        </div>
      </div>

      {/* Assets List */}
      <div className="mt-10">
        {isLoading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Loading Skeleton */}
            {Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="p-6 bg-white rounded-lg shadow animate-pulse">
                <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-2/3 mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </div>
            ))}
          </div>
        ) : assets.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {assets
              .filter(asset =>
                asset.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((asset) => (
                <div
                  key={asset.id}
                  className="flex flex-col bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition cursor-pointer"
                  onClick={() => openModal(asset)}
                >
                  {asset.image_url ? (
                    <img
                      src={asset.image_url}
                      alt={asset.name}
                      className="w-full h-auto max-h-40 object-contain rounded-lg mb-4"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/path/to/default-image.png';
                      }}
                    />
                  ) : (
                    <GenericAssetIcon />
                  )}
                  <h2 className="text-xl font-semibold text-gray-800">{asset.name}</h2>
                  <p className="text-sm text-gray-500">{asset.category}</p>
                  <p className="text-sm text-gray-500">{asset.responsible}</p>
                  <span className={`mt-2 px-4 py-1 text-sm font-semibold rounded-full 
                    ${asset.status === 'Disponível' ? 'bg-green-200 text-green-800' :
                    asset.status === 'Em uso' ? 'bg-yellow-200 text-yellow-800' :
                    asset.status === 'Em manutenção' ? 'bg-red-200 text-red-800' : 'bg-gray-200 text-gray-800'}
                    flex items-center justify-center gap-2`}>
                    {asset.status === 'Disponível' ? (
                      <div className="w-2.5 h-2.5 bg-green-500 rounded-full"></div>
                    ) : asset.status === 'Em uso' ? (
                      <div className="w-2.5 h-2.5 bg-yellow-500 rounded-full"></div>
                    ) : asset.status === 'Em manutenção' ? (
                      <div className="w-2.5 h-2.5 bg-red-500 rounded-full"></div>
                    ) : (
                      <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    )}
                    {asset.status}
                  </span>
                </div>
              ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center mt-20 text-center">
            <div className="w-24 h-24 bg-gray-200 rounded-lg flex items-center justify-center relative">
              <div className="w-14 h-8 bg-gray-300 rounded-sm"></div>
              <div className="absolute bottom-1 w-4 h-1 bg-gray-500 rounded-full"></div>
              <div className="absolute bottom-1 right-4 w-2 h-2 bg-gray-400 rounded-full"></div>
            </div>
            <p className="mt-6 text-lg font-semibold text-gray-700">Nenhum ativo cadastrado</p>
            <p className="mt-2 text-gray-500">O que está esperando? Crie seu primeiro ativo agora mesmo!</p>
          </div>
        )}
      </div>

      {/* Modal - This would need to be updated to match the mock data structure */}
      {isModalOpen && selectedAsset && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-lg w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">{selectedAsset.name}</h2>
              <button 
                onClick={closeModal} 
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-600">Categoria</p>
                <p className="font-semibold">{selectedAsset.category}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Status</p>
                <p className="font-semibold">{selectedAsset.status}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Responsável</p>
                <p className="font-semibold">{selectedAsset.responsible}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Data de Aquisição</p>
                <p className="font-semibold">{selectedAsset.acquisition_date}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Valor</p>
                <p className="font-semibold">R$ {selectedAsset.value}</p>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-sm text-gray-600">Descrição</p>
              <p>{selectedAsset.description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InventoryPage;